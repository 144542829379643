import {h} from '@stencil/core';

export const SVG_INFO = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Icons">
    <path d="M12,22.028A10.028,10.028,0,1,1,22.028,12,10.04,10.04,0,0,1,12,22.028Zm0-19A8.972,8.972,0,1,0,20.972,12,8.982,8.982,0,0,0,12,3.028Z"/>
    <path d="M12,17a.5.5,0,0,1-.5-.5V11a.5.5,0,0,1,1,0v5.5A.5.5,0,0,1,12,17Z"/>
    <path d="M12,9a.5.5,0,0,1-.5-.5V8a.5.5,0,0,1,1,0v.5A.5.5,0,0,1,12,9Z"/>
  </g>
</svg>;

export const SVG_ERROR_TRIANGLE = () => <svg width="8px" height="6px" viewBox="0 0 8 6" version="1.1"
                                      xmlns="http://www.w3.org/2000/svg">
  <g id="Inputs" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-52.000000, -212.000000)" fill="#BE1413"
       fill-rule="nonzero">
      <path
        d="M56.4160251,212.222703 L60,217.598665 L52,217.598665 L55.5839749,212.222703 C55.7371511,211.992939 56.0475858,211.930852 56.2773501,212.084028 C56.3322762,212.120645 56.3794077,212.167777 56.4160251,212.222703 Z"
        id="triangle-shape"/>
    </g>
  </g>
</svg>;

export const SVG_TRIANGLE = () => <svg width="8px" height="6px" viewBox="0 0 8 6" version="1.1"
                                             xmlns="http://www.w3.org/2000/svg">
  <g id="Inputs" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-52.000000, -212.000000)" fill="#FFFFFF"
       fill-rule="nonzero">
      <path
        d="M56.4160251,212.222703 L60,217.598665 L52,217.598665 L55.5839749,212.222703 C55.7371511,211.992939 56.0475858,211.930852 56.2773501,212.084028 C56.3322762,212.120645 56.3794077,212.167777 56.4160251,212.222703 Z"
        id="triangle-shape"/>
    </g>
  </g>
</svg>;

export const SVG_EXCLAMATION_MARK = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Icons">
    <path d="M11.5,9v5a.5.5,0,0,0,1,0V9a.5.5,0,0,0-1,0Z"/>
    <circle cx="12" cy="17.5" r="0.75"/>
    <path d="M21.937,20.256l-9.5-17a.52.52,0,0,0-.874,0l-9.5,17A.5.5,0,0,0,2.5,21h19a.5.5,0,0,0,.437-.744ZM3.352,20,12,4.525,20.648,20Z"/>
  </g>
</svg>;

export const SVG_ATTENTION = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Icons"><path d="M11.5,9v5a.5.5,0,0,0,1,0V9a.5.5,0,0,0-1,0Z"/>
    <circle cx="12" cy="17.5" r="0.75"/>
    <path d="M21.937,20.256l-9.5-17a.52.52,0,0,0-.874,0l-9.5,17A.5.5,0,0,0,2.5,21h19a.5.5,0,0,0,.437-.744ZM3.352,20,12,4.525,20.648,20Z"/>
  </g>
</svg>;

export const SVG_CHECKMARK = () => <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
  <defs>
    <clipPath id="clip-path">
      <path id="Pfad_67" data-name="Pfad 67" d="M20.47-16.292a.835.835,0,0,1-.587-.24L13.66-22.677a.813.813,0,0,1-.215-.792.825.825,0,0,1,.587-.58.837.837,0,0,1,.8.212l5.643,5.572L32.13-29.474a.838.838,0,0,1,.8-.2.825.825,0,0,1,.581.586.813.813,0,0,1-.224.79L21.049-16.528A.835.835,0,0,1,20.47-16.292Z" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-2">
      <path id="Pfad_66" data-name="Pfad 66" d="M-305,1053H899V-107H-305Z" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-3">
      <path id="Pfad_70" data-name="Pfad 70" d="M23-44.357A21.357,21.357,0,0,1,44.357-23,21.357,21.357,0,0,1,23-1.643,21.357,21.357,0,0,1,1.643-23,21.382,21.382,0,0,1,23-44.357M23-46A23,23,0,0,0,0-23,23,23,0,0,0,23,0,23,23,0,0,0,46-23a23,23,0,0,0-6.737-16.263A23,23,0,0,0,23-46Z" fill="none"/>
    </clipPath>
  </defs>
  <g id="Gruppe_63" data-name="Gruppe 63" transform="translate(0 46)">
    <g id="Gruppe_60" data-name="Gruppe 60" clip-path="url(#clip-path)">
      <g id="Gruppe_59" data-name="Gruppe 59" clip-path="url(#clip-path-2)">
        <path id="Pfad_65" data-name="Pfad 65" d="M8.417-34.708H38.542v23.417H8.417Z" fill="#6da318"/>
      </g>
    </g>
    <g id="Gruppe_62" data-name="Gruppe 62" clip-path="url(#clip-path-3)">
      <g id="Gruppe_61" data-name="Gruppe 61" clip-path="url(#clip-path-2)">
        <path id="Pfad_68" data-name="Pfad 68" d="M-5-51H51V5H-5Z" fill="#6da318"/>
      </g>
    </g>
  </g>
</svg>;

export const SVG_PLUS = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g>
    <path d="M17.5,11.5h-5v-5a.5.5,0,0,0-1,0v5h-5a.5.5,0,0,0,0,1h5v5a.5.5,0,0,0,1,0v-5h5a.5.5,0,0,0,0-1Z"/>
  </g>
</svg>;

export const SVG_EDIT = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g>
    <path
      d="M21.854,5.146l-3-3a.5.5,0,0,0-.708,0l-15,15a.515.515,0,0,0-.131.233l-1,4A.5.5,0,0,0,2.5,22a.5.5,0,0,0,.121-.015l4-1a.5.5,0,0,0,.233-.131l15-15A.5.5,0,0,0,21.854,5.146ZM8.5,17.793,6.207,15.5,16.5,5.207,18.793,7.5ZM6.244,20.049l-3.057.763.765-3.056L5.5,16.207,7.793,18.5ZM19.5,6.793,17.207,4.5,18.5,3.207,20.793,5.5Z"/>
  </g>
</svg>;

export const SVG_NEW_TAB = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g>
    <path
      d="M18.962,6.309A.5.5,0,0,0,18.5,6H13a.5.5,0,0,0,0,1h4.293l-7.147,7.146a.5.5,0,0,0,.708.708L18,7.707V12a.5.5,0,0,0,1,0V6.5A.5.5,0,0,0,18.962,6.309Z"/>
    <path d="M18.5,21H5.5a.5.5,0,0,0,0,1h13a.5.5,0,0,0,0-1Z"/>
  </g>
</svg>;

export const SVG_RELOAD = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g>
    <path
      d="M8,1A.75.75,0,0,0,8,2.5,5.5,5.5,0,1,1,2.5,8,5.443,5.443,0,0,1,4,4.236V5.5a.75.75,0,0,0,1.5,0v-3a.75.75,0,0,0-.75-.75h-3a.75.75,0,0,0,0,1.5H2.871A6.927,6.927,0,0,0,1,8,7,7,0,1,0,8,1Z"/>
  </g>
</svg>;

export const SVG_CLEAR = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g id="Beschriftung" fill="#a7893d">
    <path
      d="M9.061,8l1.944-1.944A.75.75,0,1,0,9.945,5L8,6.94,6.055,5A.75.75,0,0,0,5,6.056L6.939,8,5,9.944a.75.75,0,1,0,1.06,1.061L8,9.06l1.945,1.945a.75.75,0,1,0,1.06-1.061Z"/>
  </g>
</svg>;

export const SVG_CLOSE = () =>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g fill="none" fill-rule="evenodd">
    <g fill="#A7893D">
      <g>
        <g>
          <path d="M8.708 8l6.147-6.145c.195-.196.195-.513 0-.708-.196-.196-.513-.196-.708 0L8 7.294 1.855 1.147c-.196-.196-.513-.196-.708 0-.196.195-.196.512 0 .708L7.294 8l-6.147 6.146c-.196.195-.196.512 0 .708.195.195.512.195.708 0L8 8.708l6.146 6.147c.195.195.512.195.708 0 .195-.196.195-.513 0-.708L8.708 8z" transform="translate(-1133 -48) translate(273 24) translate(860 24)"/>
        </g>
      </g>
    </g>
  </g>
</svg>;

